module.exports = {
    'order-sales-report.list.title': 'Eladások megrendelésenként',
    'order-sales-report.list.total-box': 'Totál Tény Ár',
    'order-sales-report.list.table.header.orderId': 'Azonosító',
    'order-sales-report.list.table.header.customer-partner': 'Vevőcég',
    'order-sales-report.list.table.header.factTotal': 'Tény ár',
    'order-sales-report.list.table.header.planTotal': 'Terv ár',
    'order-sales-report.list.table.header.salesType': 'Típus',
    'order-sales-report.list.table.header.channel': 'Csatorna',
    'order-sales-report.list.table.header.salesManager': 'Értékesítő',
    'order-sales-report.list.table.header.createdBy': 'Létrehozó',
    'order-sales-report.list.table.header.createdAt': 'Dátum',
    'order-sales-report.filter.salesManager': 'Értékesítők',
    'order-sales-report.filter.suppliers': 'Beszállítók',
    'order-sales-report.filter.creator': 'Megrendelés létrehozója',
    'order-sales-report.filter.customer-partner': 'Vevőpartner',
    'order-sales-report.filter.warehouse': 'Telephely',
    'order-sales-report.list.order-product.table.header.id': 'Termék',
    'order-sales-report.list.order-product.table.header.factAmount': 'Tény darab',
    'order-sales-report.list.order-product.table.header.sumFactTruePrice': 'Tény összeg',
    'order-sales-report.list.order-product.table.header.planAmount': 'Terv darab',
    'order-sales-report.list.order-product.table.header.sumPlanTruePrice': 'Terv összeg',
    'product-sales-by-orders-report.screen.title': 'Számlázott forgalmi kimutatás',
    'product-sales-by-orders-report.list.header.title': 'Termék',
    'product-sales-by-orders-report.list.header.sku': 'SKU',
    'product-sales-by-orders-report.list.header.supplierPriceTotal': 'Beszállító ár Totál',
    'product-sales-by-orders-report.list.header.capacity': 'Kiszerelés',
    'product-sales-by-orders-report.list.header.vintage': 'Évjárat',
    'product-sales-by-orders-report.list.header.netListPrice': 'Nettó Listaár',
    'product-sales-by-orders-report.list.header.supplierPrice': 'Beszállító ár',
    'product-sales-by-orders-report.list.header.amount': 'Mennyiség üveg',
    'product-sales-by-orders-report.list.header.total': 'Összesen',
    'product-sales-by-orders-report.supplierPrice': 'Beszállító ár',
    'product-sales-by-orders-report.supplierPriceModifier': 'Beszállító akciós ár',
    'product-sales-by-orders-report.supplierPriceForCustomerPartner': 'Vevőpartner beszállító ár',
    'product-sales-by-orders-report.supplierModifierForCustomerPartner': 'Vevőpartner beszállító akció ár',
};
